import { DownloadIcon } from "../components/Icons";
import Header from "./../components/Title";

export default function Home() {
    return (
        <section className="min-h-[100dvh] pt-6 max-w-3xl mx-5 md:mx-auto flex flex-col justify-center items-center go-up-animation">
            <div className="text-sm md:text-xl uppercase">
                <span className="font-bold"> Hello!</span>{" "}
                <span className="pl-1 md:pl-3 font-extralight">My name is</span>
            </div>
            <Header word="Vijayakumar" className="mt-5 md:mt-10" />
            <Header word="Mathaiyan" className="mt-2 md:mt-6" />
            <div className="text-base mt-5 w-4/5 md:text-lg md:mt-10 md:w-3/5 text-center tracking-wide">
                I’m a frontend developer who thrive on crafting elegant
                solutions to intricate challenges, driven by a fervent passion
                for web development.
            </div>
            <div className="text-sm mt-5 flex items-center justify-center hidden">
                <span className="flex items-center justify-center h-6 rounded-full border px-3 ml-1 text-sm cursor-pointer border-color noto-sans-mono-regular">
                    RESUME
                    <DownloadIcon className="h-5 w-5 ml-1" />
                </span>
            </div>
        </section>
    );
}
