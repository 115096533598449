const skills = [
    {
        key: "html5",
        name: "HTML5"
    },
    {
        key: "css3",
        name: "CSS3"
    },
    {
        key: "javascript",
        name: "JavaScript"
    },
    {
        key: "typescript",
        name: "TypeScript"
    },
    {
        key: "react",
        name: "React"
    },
    {
        key: "vue",
        name: "Vue"
    },
    {
        key: "tailwind",
        name: "Tailwind"
    },
    {
        key: "nodejs",
        name: "node js"
    },
    {
        key: "jest",
        name: "jest"
    },
    {
        key: "react_testing_library",
        name: "react testing library"
    },
    {
        key: "aws_route_53",
        name: "aws route 53"
    },
    {
        key: "aws_s3",
        name: "aws s3"
    },
    {
        key: "aws_ec2",
        name: "aws ec2"
    }
];
export default function Skills() {
    return (
        <section className="min-h-[100dvh] pt-6 max-w-5xl mx-10 sm:mx-15 md:mx-30 xl:mx-auto flex flex-col justify-center go-up-animation">
            <div className="text-base md:text-2xl font-extralight md:font-thin uppercase tracking-wide">
                Skills
            </div>
            <div
                className={`permanent-marker-regular text-2xl mt-3 sm:text-3xl sm:mt-6 md:text-5xl md:mt-10`}
            >
                Continuous learning is the key to mastering new skills and
                staying relevant in today’s trend
            </div>
            <div className="text-sm md:text-base mt-5">
                With my experience, I’ve cultivated following versatile skill
                set essential for delivering high-quality projects.
            </div>
            <div
                className={`noto-sans-mono-regular flex flex-wrap justify-between text-xs w-full lg:w-3/4`}
            >
                {skills.map(({ key, name }) => {
                    return (
                        <div
                            className="flex items-center justify-center h-6 rounded-full border-color border uppercase px-4 ml-1 mt-2 md:ml-3 md:mt-5"
                            key={key}
                        >
                            <span>{name}</span>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}
