"use client";
import { ChatIcon, CopyIcon, LinkIcon } from "../components/Icons";
import { message } from "antd";
export default function Contact() {
    const [messageApi, contextHolder] = message.useMessage();
    const showChatWindow = function () {
        //window.$zoho.salesiq.floatbutton.visible("show");
        window.$zoho.salesiq.floatwindow.visible("show");
    };
    return (
        <section className="min-h-[100dvh] pt-6 max-w-5xl mx-10 sm:mx-15 md:mx-30 xl:mx-auto flex flex-col justify-center go-up-animation">
            {contextHolder}
            <div className="text-base md:text-2xl font-extralight md:font-thin uppercase tracking-wide">
                Contact
            </div>
            <div
                className={`text-2xl mt-3 sm:text-3xl sm:mt-6 md:text-5xl md:mt-10 permanent-marker-regular`}
            >
                Let’s stay connected.
            </div>
            <div className="text-sm md:text-base mt-5">
                Whether you have a question or just want to say hello, I’ll try
                my best to get back to you!
            </div>
            <div className="text-sm md:text-base mt-3 flex items-center justify-start flex-wrap">
                Mail to{" "}
                <span
                    className="select-all nato-sans-mono-regular flex items-center justify-center h-6 border-color rounded-full border px-3 text-xs cursor-pointer mt-2 md:ml-1 md:mt-0"
                    onClick={() => {
                        navigator.clipboard.writeText(
                            "vijayakumar.ml.webdev@gmail.com"
                        );
                        messageApi.open({
                            type: "success",
                            content: "Email copied!"
                        });
                    }}
                >
                    vijayakumar.ml.webdev@gmail.com{" "}
                    <CopyIcon className="h-4 w-4 ml-1" />
                </span>
            </div>
            <div className="text-sm md:text-base mt-3 flex items-center justify-start flex-wrap">
                Alternatively, you can also send me a message on Linkedin
                <a
                    href="https://www.linkedin.com/in/vijayakumar-madhaiyan"
                    target="_blank"
                    className="nato-sans-mono-regular flex items-center justify-center h-6 border-color rounded-full border px-3 text-xs cursor-pointer mt-2 md:ml-1 md:mt-0"
                >
                    vijayakumar-madhaiyan
                    <LinkIcon className="h-4 w-4 ml-1" />
                </a>
            </div>
            <div className="text-sm md:text-base mt-3 flex items-center justify-start flex-wrap">
                <span>Or</span>
                <span
                    className="nato-sans-mono-regular flex items-center justify-center h-6 border-color rounded-full border px-3 text-xs cursor-pointer ml-1 md:mt-0"
                    onClick={showChatWindow}
                >
                    chat here
                    <ChatIcon className="h-4 w-4 ml-1" />
                </span>
            </div>
        </section>
    );
}
