export default function About() {
    return (
        <section className="min-h-[100dvh] pt-6 max-w-5xl mx-10 sm:mx-15 md:mx-30 xl:mx-auto flex flex-col justify-center go-up-animation">
            <div className="text-base md:text-2xl font-extralight md:font-thin uppercase tracking-wide">
                About
            </div>
            <div
                className={`permanent-marker-regular text-2xl mt-3 sm:text-3xl sm:mt-6 md:text-5xl md:mt-10`}
            >
                a frontend developer with affinity for design, intuitive user
                experience and pixel-perfect user interfaces
            </div>
            <div className="text-sm md:text-base mt-5">
                I am a frontend-focused software engineer with 6 years of
                professional development experience, holding a degree of BE
                Computer Science from the PSNA College of Engineering and
                Technology.
            </div>
            <div className="text-sm md:text-base mt-5">
                I have been employed by leading tech company (Zoho) and amazing
                emerging startup company (Zomentum) throughout my career thus
                far.
            </div>
        </section>
    );
}
