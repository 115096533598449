"use client";
import { useEffect, useState } from "react";
import {
    ChatIcon,
    MoonIcon,
    OpenBookIcon,
    StartIcon,
    SunIcon,
    UserIcon
} from "./Icons";
import { useLocation, useNavigate } from "react-router-dom";
export default function NavBar({ isMenu }: { isMenu?: boolean }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const handleThemeChange = (theme: string) => {
        setIsDarkTheme(theme === "dark");
        const { classList } = window.document.documentElement;
        if (theme === "dark") {
            classList.add(theme);
        } else {
            classList.remove("dark");
        }
        localStorage.setItem("theme", theme);
    };
    useEffect(() => {
        const isDark =
            window.document.documentElement.classList.contains("dark");
        setIsDarkTheme(isDark);
    }, []);
    const Paths = [
        {
            pathname: "/about",
            name: "About",
            icon: <UserIcon className="h-5 w-5" />
        },
        {
            pathname: "/skills",
            name: "Skills",
            icon: <StartIcon className="h-6 w-6" />
        },
        {
            pathname: "/projects",
            name: "Projects",
            icon: <OpenBookIcon className="h-5 w-5" />
        },
        {
            pathname: "/contact",
            name: "Contacts",
            icon: <ChatIcon className="h-5 w-5" />
        }
    ];
    if (isMenu) {
        return (
            <section className="relative max-w-5xl mx-10 sm:mx-15 md:mx-30 xl:mx-auto md:hidden">
                <div className="flex absolute items-center justify-center bottom-8 left-0 w-full">
                    <ul className="list-none text-xs uppercase flex gap-5 items-center justify-center h-6 rounded-full border-color border px-3">
                        {Paths.map((path) => {
                            return (
                                <li
                                    className="flex justify-center cursor-pointer group"
                                    onClick={() => navigate(path.pathname)}
                                    key={path.pathname}
                                >
                                    <span
                                        className={
                                            "noto-sans-mono-regular " +
                                            (pathname === path.pathname
                                                ? "underline"
                                                : "")
                                        }
                                    >
                                        {path.name}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </section>
        );
    }
    return (
        <section className="relative max-w-5xl mx-10 sm:mx-15 md:mx-30 xl:mx-auto">
            <div className="flex absolute items-center justify-between top-8 left-0 w-full">
                <span
                    className="rock-salt-regular cursor-pointer group"
                    onClick={() => navigate("/")}
                >
                    {pathname === "/" ? "Vijay" : "Vijayakumar"}
                </span>
                <ul className="list-none text-sm uppercase flex gap-5 items-center justify-center">
                    {Paths.map((path) => {
                        return (
                            <li
                                className="hidden justify-center cursor-pointer md:flex"
                                onClick={() => navigate(path.pathname)}
                                key={path.pathname}
                            >
                                <span
                                    className={
                                        "noto-sans-mono-regular " +
                                        (pathname === path.pathname
                                            ? "underline"
                                            : "")
                                    }
                                >
                                    {path.name}
                                </span>
                            </li>
                        );
                    })}
                    <li className="cursor-pointer" key="theme">
                        {isDarkTheme ? (
                            <SunIcon
                                className="w-5 h-5"
                                onClick={() => handleThemeChange("light")}
                            />
                        ) : (
                            <MoonIcon
                                className="w-5 h-5"
                                onClick={() => handleThemeChange("dark")}
                            />
                        )}
                    </li>
                </ul>
            </div>
        </section>
    );
}
