export default function NotFound() {
    return (
        <section className="min-h-[100dvh] pt-6 max-w-3xl mx-5 md:mx-auto flex flex-col justify-center items-center go-up-animation">
            <div
                className={`text-5xl mt-3 sm:text-7xl sm:mt-6 md:text-9xl md:mt-10 noto-sans-mono-regular`}
            >
                404
            </div>
            <div
                className={`text-xl mt-3 sm:text-2xl sm:mt-6 md:text-4xl md:mt-10 uppercase rock-salt-regular`}
            >
                page not found
            </div>
        </section>
    );
}
