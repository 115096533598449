"use client";
interface Props {
    className?: string;
    onClick?: () => void;
}
export const LinkIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <g clipPath="url(#a)">
                <path
                    d="m16.243 12 2.828-2.828s1.414-1.415-.707-3.536-3.536-.707-3.536-.707l-3.535 3.535C9.879 9.88 9.879 11.293 10.586 12m-2.829 0L4.93 14.828s-1.414 1.415.707 3.536 3.536.707 3.536.707l3.182-3.182c1.414-1.414 1.767-3.182 1.06-3.889"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export const DownloadIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <path
                d="M5 12v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-6m-7-9v12m0 0 4-4m-4 4-4-4"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const CopyIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <rect
                x="4"
                y="8"
                width="12"
                height="12"
                rx="1"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 6V5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="2 2"
            />
        </svg>
    );
};

export const SunIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={props.className}
            onClick={props.onClick}
        >
            <path
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                stroke="currentColor"
                fill="none"
            ></path>
            <path
                d="M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836"
                stroke="currentColor"
            ></path>
        </svg>
    );
};

export const MoonIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z"
                className="fill-transparent"
            ></path>
            <path
                d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z"
                fill="currentColor"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z"
                fill="currentColor"
            ></path>
        </svg>
    );
};

export const UserIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="1"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <g clipPath="url(#a)">
                <g filter="url(#b)">
                    <path
                        d="m14.337 12.347-.26-.428a.5.5 0 0 0 .114.906zm-4.674 0 .146.478a.5.5 0 0 0 .114-.905zm-5.601 6.655-.497-.062zm15.876 0 .497-.062zM16 8.5a4 4 0 0 1-1.924 3.42l.52.854A5 5 0 0 0 17 8.5zm-4-4a4 4 0 0 1 4 4h1a5 5 0 0 0-5-5zm-4 4a4 4 0 0 1 4-4v-1a5 5 0 0 0-5 5zm1.923 3.42A4 4 0 0 1 8 8.5H7c0 1.811.963 3.397 2.403 4.274zm-.405-.052a8.51 8.51 0 0 0-5.953 7.072l.993.124a7.51 7.51 0 0 1 5.251-6.24zM3.565 18.94c-.11.888.626 1.56 1.435 1.56v-1c-.295 0-.468-.228-.442-.436zM5 20.5h14v-1H5zm14 0c.81 0 1.545-.672 1.435-1.56l-.993.124c.026.208-.147.436-.442.436zm1.435-1.56a8.51 8.51 0 0 0-5.953-7.072l-.291.957a7.51 7.51 0 0 1 5.251 6.239z"
                        stroke="currentColor"
                    />
                </g>
            </g>
        </svg>
    );
};
export const StartIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <path
                d="M11.525 4.464a.5.5 0 0 1 .95 0l1.433 4.41a.5.5 0 0 0 .476.345h4.637a.5.5 0 0 1 .294.904l-3.752 2.726a.5.5 0 0 0-.181.559l1.432 4.41a.5.5 0 0 1-.769.559l-3.751-2.726a.5.5 0 0 0-.588 0l-3.751 2.726a.5.5 0 0 1-.77-.56l1.433-4.41a.5.5 0 0 0-.181-.558l-3.752-2.726a.5.5 0 0 1 .294-.904h4.637a.5.5 0 0 0 .476-.346z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export const OpenBookIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <path
                d="M12 6.91c-1.1-1.401-2.796-2.801-6.999-2.904A.49.49 0 0 0 4.5 4.5v12.097c0 .276.225.493.501.502 4.203.137 5.899 2 6.999 3.401m0-13.59c1.1-1.401 2.796-2.801 6.999-2.904a.487.487 0 0 1 .501.489v12.101a.51.51 0 0 1-.501.503c-4.203.137-5.899 2-6.999 3.401m0-13.59V20.5"
                stroke="currentColor"
                strokeLinejoin="round"
            />
            <path
                d="M19.235 6H21.5a.5.5 0 0 1 .5.5v13.039c0 .405-.477.673-.846.51-.796-.354-2.122-.786-3.86-.786C14.353 19.263 12 21 12 21s-2.353-1.737-5.294-1.737c-1.738 0-3.064.432-3.86.785-.37.164-.846-.104-.846-.509V6.5a.5.5 0 0 1 .5-.5h2.265"
                stroke="currentColor"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export const ChatIcon: React.FC<Props> = function (props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            onClick={props.onClick}
        >
            <g clipPath="url(#a)">
                <path fill="#fff0" d="M0 0h24v24H0z" />
                <path
                    d="M20 12a8 8 0 0 1-11.876 7c-.591-.328-3.637 1.462-4.124 1-.559-.53 1.458-3.33 1.07-4A8 8 0 1 1 20 12Z"
                    stroke="currentColor"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
