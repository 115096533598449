export default function Projects() {
    return (
        <section className="min-h-[100dvh] pt-6 max-w-5xl mx-10 sm:mx-15 md:mx-30 xl:mx-auto flex flex-col justify-center go-up-animation">
            <div className="text-base md:text-2xl font-extralight md:font-thin uppercase tracking-wide">
                Projects
            </div>
            <div
                className={`text-2xl mt-3 sm:text-3xl sm:mt-6 md:text-5xl md:mt-10 permanent-marker-regular`}
            >
                It’s in the works!
            </div>
            <div className="text-sm md:text-base mt-5">Check back soon.</div>
            <div className="text-xs md:text-sm mt-10">
                Meanwhile, this site is being built & designed with ReactJS,
                TypeScript, Tailwind CSS
            </div>
        </section>
    );
}
