interface HeaderProps {
    word: string;
    className?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
    const chars = props.word.split("");
    return (
        <div
            className={`text-center uppercase tracking-normal permanent-marker-regular ${props.className} text-4xl sm:text-6xl lg:text-8xl`}
        >
            {chars.map((letter, index) => {
                return (
                    <span
                        key={letter + index}
                        className="inline-block ml-1 translate-x-0 translate-y-0 transition-all duration-200 ease-in-out md:hover:origin-[50%_100%] md:hover:-rotate-3 md:hover:scale-125"
                    >
                        {letter}
                    </span>
                );
            })}
        </div>
    );
};
export default Header;
